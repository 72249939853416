import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
//services
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { LoaderService } from '../../services/loader.service'
import { ErrorHandler } from '../../services/errorhandling'
// components
import { PrintQrComponent } from '../print-qr/print-qr.component';
// import html2canvas from 'html2canvas';

// import * as JSZip from 'jszip';

import { AllowedServicesConstants as Constants } from '../../shared/constants/allowedServices.constant';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  // modal variables
  bsModalRef: BsModalRef;

  public users: any = {};
  public isLoading: boolean = false;
  public searchQuery: string = "";
  public pageNo: number = 1;
  public pageSize: number = 10;
  public bulkUsers = [];
  public bulkMode: boolean = false;
  public bulkModePanel: boolean = false;

  public readonly CONSTANTS = Constants;

  constructor(private userService: UserService,
    private modalService: BsModalService,
    private router: Router,
    private loaderService: LoaderService,
    public errorHandler: ErrorHandler) { }

  ngOnInit() {
    this.fetchUsers();

    this.fetchBulkUsersForDownload();
  }

  fetchBulkUsersForDownload() {
    this.userService.fetchBulkUsersForDownload().subscribe(
      (res: any) => {
        this.bulkUsers = res.data;
      }, err => { }
    );
  }

  public fetchUsersUponSubmit() {
    this.pageNo = 1;
    this.fetchUsers();
  }

  clearUser() {
    if (this.searchQuery != "") {
      this.searchQuery = "";
      this.pageNo = 1;
      this.fetchUsers();
    }
  }

  public pageChanged(event: any): void {
    this.pageNo = event.page;
    this.pageSize = event.itemsPerPage;
    this.fetchUsers();
  };

  fetchUsers() {
    let filterParams = { filters: { user: '' } };
    let userRole = JSON.parse(localStorage.getItem('userData')).userRole || '';
    if(userRole &&  userRole === 'ROLE_TRAINEE_ADMIN')
    {
      filterParams['filters']['userType'] = 1;
    }
    if (this.searchQuery)
      filterParams['filters']['user'] = this.searchQuery;
    this.isLoading = true;
    this.userService.fetchUsers(this.pageNo, this.pageSize, filterParams).subscribe(
      res => {
        if (res.status === 200) {
          this.users = res.data[0];
        }
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        //this.errorHandler.handleError(err);
      }
    );
  }


  printQr(event, user: any) {
    if (event.stopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.loaderService.$showLoader = true;
    this.userService.getUserBySurrogateId(user.userSurrogateId).subscribe(
      res => {
        if (res.status === 200) {
          let data = res.data[0];
          let modelData = {};
          modelData['userSurrogateId'] = data.userSurrogateId;
          modelData['name'] = data.name;
          modelData['surname'] = data.surname;
          modelData['profilePath'] = 'data:image/jpeg;base64,' + data.profileImageBase64;
          modelData['qrCodePath'] = 'data:image/jpeg;base64,' + data.qrcodeBase64;
          modelData['mobileNumber'] = data.mobileNumber;
          modelData['niNumber'] = data.userDetails.niNumber;
          const initialState = {
            modelData: modelData
          };
          if (this.bsModalRef) {
            this.bsModalRef.hide();
          }
          this.loaderService.$showLoader = false;
          this.bsModalRef = this.modalService.show(PrintQrComponent, { backdrop: 'static', class: 'modal-dialog-centered', initialState });
          this.bsModalRef.content.action
            .subscribe((value) => {
              this.bsModalRef.hide();
            }, (err) => {
              console.log("error occured while closing popup");
              this.bsModalRef.hide();
              return false;
            });
        } else {
          this.loaderService.$showLoader = false;
          this.errorHandler.showLocalizedMessage(res.errors.errorMessage, 'error');
        }

      }, err => {
        this.loaderService.$showLoader = false;
        this.errorHandler.showLocalizedMessage(err, 'error');
      });
  }

  doEdit(userSurrogateId: string) {
    this.router.navigate(['/register', userSurrogateId]);
  }

  gotoRegister() {
    this.router.navigate(['/register']);
  }

  onChecked(user: any, event: any) {
    event.stopPropagation();
    user.isChecked = event.target.checked;
    if (user.isChecked) {
      for (let index = 0; index < this.bulkUsers.length; index++) {
        const element = this.bulkUsers[index];
        if (element.userSurrogateId === user.userSurrogateId) {
          element.isChecked = true;
          break;
        }
      }
      this.userService.$bulkUsers = this.bulkUsers;
      this.tickAndunTickSelectAllInputElement();
    } else {
      this.removeCardFromSelected(user, null, 'checkbox');
    }
  }

  removeCardFromSelected(user: any, selectedUser: any, removedBy: string) {  // removedBy : 'checkbox' or 'button'
    let userSurrogateId = '';
    if (removedBy === 'checkbox') {
      userSurrogateId = user.userSurrogateId;
      user.isChecked = false;
      this.bulkUsers.forEach(element => {
        if (element.userSurrogateId === userSurrogateId)
          element.isChecked = false;
      });
    } else {
      userSurrogateId = selectedUser.userSurrogateId;
      selectedUser.isChecked = false;
      this.users.resultList.forEach(element => {
        if (element.userSurrogateId === userSurrogateId)
          element.isChecked = false;
      });
    }
    this.userService.$bulkUsers = this.bulkUsers;
    this.tickAndunTickSelectAllInputElement();
  }

  selectAll(event: any) {
    let selectAll = event.target.checked;
    this.checkOrUncheckSelectedUsers(selectAll);
    this.tickAndunTickSelectAllInputElement();
  }

  enableBulkDownload() {
    this.bulkMode = true;
    this.bulkModePanel = true;
  }

  cancelBulkDownload() {
    this.bulkMode = false;
    this.checkOrUncheckSelectedUsers(this.bulkMode);
  }

  private checkOrUncheckSelectedUsers(clear: boolean) {
    this.bulkUsers.forEach(element => {
      element.isChecked = clear;
    });
    this.users.resultList.forEach(user => {
      user.isChecked = clear;
    });
    this.userService.$bulkUsers = this.bulkUsers;
  }

  tickAndunTickSelectAllInputElement() {
    let selectAll = <HTMLInputElement>document.getElementById('select-all');
    let selectedCount: number = 0;
    this.bulkUsers.forEach(element => {
      if (element.isChecked)
        selectedCount++;
    });
    if (selectedCount > 0) {
      this.bulkModePanel = false;
      selectAll.checked = false;
    }
    if (selectedCount == 0) {
      this.bulkModePanel = true;
      selectAll.checked = false;
    }
    if (selectedCount === this.bulkUsers.length) {
      this.bulkModePanel = false;
      selectAll.checked = true;
    }

  }
  downloadImagesAsZip(event) {
    let _self = this;
    if (event.stopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }
    _self.loaderService.$showLoader = true;
    let delayTime: number = 0;
    let selectedList = [];
    _self.bulkUsers.forEach(element => {
      if (element.isChecked) {
        delayTime = delayTime + 1;
        selectedList.push(element.userSurrogateId);
      }
    });

    if (delayTime === 0) {
      _self.loaderService.$showLoader = false;
      _self.errorHandler.showLocalizedMessage('Please select user', 'error');
      return;
    }
    this.userService.downloadUsersIDCardsAsZip(selectedList).subscribe(
      (res: any) => {
        _self.loaderService.$showLoader = false;
        var file = new Blob([res], {
          type: "application/zip"
        });
        var fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'UserIDCards.zip';
        document.body.appendChild(a);
        a.click();
        _self.cancelBulkDownload();
      }, (err: any) => {
        _self.loaderService.$showLoader = false;
        this.errorHandler.showLocalizedMessage('Error occured while downloading', 'error');
      }
    )

  }



  /* downloadImagesAsZip(event) {
     let _self = this;
     if (event.stopPropagation) {
       event.stopPropagation();
       event.preventDefault();
     }
     _self.loaderService.$showLoader = true;
     // timeout calculation based user selected length
     let delayTime: number = 0;
     _self.bulkUsers.forEach(element => {
       if (element.isChecked) {
         delayTime = delayTime + 1;
       }
     });
 
     if (delayTime === 0) {
       _self.loaderService.$showLoader = false;
       _self.errorHandler.showLocalizedMessage('Please select user', 'error');
       return;
     }
     var zip = new JSZip();
     for (let index = 0; index < _self.bulkUsers.length; index++) {
       let user = _self.bulkUsers[index];
       if (user.isChecked) {
         let html = _self.buildIDcardHTML(user)
         var iframe = document.createElement('iframe');
         iframe.style.visibility = 'hidden';
         document.body.appendChild(iframe);
 
         var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
         iframedoc.body.innerHTML = html;
         html2canvas(iframedoc.body, { height: 250, width: 510 }).then(canvas => {
           let base64 = canvas.toDataURL("image/png");
           zip.file(user.userSurrogateId + ".png", base64.replace('data:image/png;base64,', ''), { base64: true });
           if (Object.keys(zip.files).length === delayTime) {
             callback();
           }
         })
 
       }
     }
     function callback() {
       zip.generateAsync({ type: "blob" })
         .then(function (content) {
           var a = document.createElement('a');
           a.href = window.URL.createObjectURL(content);
           a.download = 'idcard_archive.zip';
           a.click();
         }).then(() => {
           document.querySelectorAll('iframe').forEach(
             function (elem) {
               elem.parentNode.removeChild(elem);
             });
           // clear everything after download  
           _self.cancelBulkDownload();
           _self.loaderService.$showLoader = false;
         });
 
     }
   } */

  /* private buildIDcardHTML(user: any): string {
     let html = `<!DOCTYPE html>
       <html lang="en">
       <head>
           <meta charset="UTF-8">
           <meta name="viewport" content="width=device-width, initial-scale=1.0">
           <meta http-equiv="X-UA-Compatible" content="ie=edge">
           <title>Idcard</title>
           <style>
           //Application fonts
 
       @font-face {
         font-family: 'charlie_textlight';
         src:
           url('../../../assets/fonts/charlie_text-light-webfont.woff2') format('woff2'),
           url('../../../assets/fonts/charlie_text-light-webfont.woff') format('woff');
         font-weight: normal;
         font-style: normal;
       }
 
       @font-face {
         font-family: 'charlie_textregular';
         src:
           url('../../../assets/fonts/charlie_text-regular-webfont.woff2') format('woff2'),
           url('../../../assets/fonts/charlie_text-regular-webfont.woff') format('woff');
         font-weight: normal;
         font-style: normal;
       }
 
       @font-face {
         font-family: 'charlie_textsemibold';
         src:
           url('../../../assets/fonts/charlie_text-semibold-webfont.woff2') format('woff2'),
           url('../../../assets/fonts/charlie_text-semibold-webfont.woff') format('woff');
         font-weight: normal;
         font-style: normal;
       }
 
       * {
         -webkit-font-smoothing: antialiased;
       }
 
       body {
         margin: 0;
         font-family: "charlie_textregular", sans-serif;
         font-size: 1rem;
         font-weight: 400;
         line-height: 1.5;
         color: #3c495a;
         text-align: left;
         background-color: #fbfdff; 
       }
       .id-card-wrapper {
         margin : 3px;
         display: flex;
       }
       .id-card {
         flex-basis: 100%;
         width: 500px;
         margin: auto;
         border: 1px solid #b8bec3;
         border-radius: 5px;
       }
 
       .profile-row {
         display: flex;
       }
       .profile-row .dp {
         flex-basis: 25.3%;
         width: 100px;
         position: relative;
         align-self: center;
         margin: 15px;
       }
       .profile-row .desc {
         flex-basis: 44.3%;
         width: 300px;
       }
 
       .profile-row .qrcode {
         flex-basis: 25.3%;
         margin: auto;
         width: 100px;
       }
 
       .profile-row .dp img {
         width: 100px;
         border-radius: 50%;
         display: block;
         height: 100px;
       }
 
       .profile-row .qrcode img {
         max-width: 90%;
         display: block;
         height: 110px;
       }
       .profile-row .desc {
         padding: 1em;
         padding-left: 0;
       }
 
       .header-row {
         display: flex;
         border-bottom: 1px solid #dee2e6;
       }
       .header-row .dp {
         flex-basis: 13.3%;
         position: relative;
         align-self: center;
         margin: 15px;
       }
       .header-row .desc {
         flex-basis: 85.3%;
           padding: 31px;
           padding-left: 0;
       }
 
       .logo{
           width:60px;
           margin:0; 
           height:60px;
       }
 
       .header-text {
           display:table-cell;
           vertical-align:middle;
           font-size: 1.2rem;
           color: #000073;
       }
 
       .mh_lh{
           max-height: 14px;
           line-height: 14px;
       }
       .brand-secondary {
           color: #27e1ba; 
       }
       .budicon-phone{
           width:25px;
           margin:0; 
           height:25px;
           margin-left: -9px;
       }
       </style>
       </head>
       <body>
           <div class="id-card-wrapper">
               <div class="id-card">
                 <div class="header-row">
                     <div class="dp">
                       <img class="logo" src="../../../assets/images/rmfConstructionLogo.png" alt="profile image">
                     </div>
                     <div class="desc">
                       <span class="header-text">RMF Construction Training Academy LTD</span>
                     </div>
                 </div>
                 <div class="profile-row">
                   <div class="dp">
                     <img src="data:image/png;base64,`+ user.profileImageBase64 + `">
                   </div>
                   <div class="desc">
                     <span style="font-size: 1.25rem;">`+ user.name + ` ` + user.surname + `</span>
                     <div style="display: flex; align-items: center; justify-content: left;margin-top:5px;">
                         <img class="budicon-phone" src="../../../assets/images/budicon-phone.png" alt="profile image">
                         <span>`+ user.mobileNumber + `</span> 
                     </div>
                     <p class="mh_lh" style="margin-top:10px;">NI No: `+ user.userDetails.niNumber + `</p>
                   </div>
                   <div class="qrcode">
                     <img src="data:image/png;base64,`+ user.qrcodeBase64 + `">
                   </div>
                 </div>
               </div>
             </div>
       </body>
       </html>`;
     return html;
   } */
}
