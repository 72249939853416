import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule, BsDatepickerModule, PaginationModule } from 'ngx-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { rmfRoutes } from './rmf.route';
import { SharedModule } from '../shared/shared.module';
import { ReportModule } from './report/report.module';

import { NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';


// components
import { RmfComponent } from './rmf.component';
import { UsersComponent } from './users/users.component';
import { HeaderComponent } from '../header/header.component';
import { PrintQrComponent } from './print-qr/print-qr.component';
// services
import { BsModalService } from 'ngx-bootstrap/modal';
import { RegisterComponent } from './register/register.component';
import { WebcamModule } from 'ngx-webcam';
import { CoursepopupComponent } from './coursepopup/coursepopup.component';



@NgModule({
  declarations: [
    RmfComponent,
    UsersComponent,
    HeaderComponent,
    PrintQrComponent,
    RegisterComponent,
    CoursepopupComponent
  ],
  imports: [
    CommonModule, FormsModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    rmfRoutes,
    SharedModule,
    ModalModule,
    WebcamModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    ReportModule
  ],
  providers: [BsModalService, DatePipe],
  entryComponents: [PrintQrComponent,
    CoursepopupComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class RmfModule { }
