import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
  selector: '[appAllowedService]'
})
export class AllowedServiceDirective {
  passedAllowedServices: string[];
  allowedServices: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {
    this.allowedServices = JSON.parse(localStorage.getItem('userData')).allowedServices;
  }
  @Input()
  set appAllowedService(allowedRoles: string[]) {
    this.passedAllowedServices = allowedRoles;

    if (!this.passedAllowedServices || this.passedAllowedServices.length === 0) {
      this.viewContainer.clear();
      return;
    }

    let allowed: boolean = false;
    this.allowedServices.forEach(
      (allowedService) => {
        this.passedAllowedServices.forEach(element => {
          if (allowedService === element) {
            allowed = true;
          }
        });
      });

    if (allowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
