import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  status: any;
  user: any;
  public bulkUsers: any = [];
  constructor(private http: HttpClient) { }
  private USER_LIST_URL = "/RMFApi/user/";
  private USER_LOGIN_URL = "/RMFAuth/oauth/token";
  private USER_ID_CARD_STORE_URL = "/RMFApi/user/store/idcard";
  private BULK_USERS_URL = "/RMFApi/user/getBulkUsers";
  private BULK_USERS_ID_DOWNLOAD_URL = "/RMFApi/user/downloadZip";
  private BULK_USERS_MAIL_ID_CARD_URL = "/RMFApi/user/mailIdCard";
  private USER_TRAINEE_SCHEDULE_LIST = "/RMFApi/user/traineeSchedule/";

  doRegister(obj: Object) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post(this.USER_LIST_URL + "/registration", obj, options);
  }

  fetchTraineeSchedule(pageNo: number, pageSize: number,searchParam: any):any{
    return this.http.post(this.USER_TRAINEE_SCHEDULE_LIST + pageNo + "/" + pageSize,searchParam)
    
  }

  fetchUsers(pageNo: number, pageSize: number, searchParam: any): any {

    return this.http.post(this.USER_LIST_URL + pageNo + "/" + pageSize, searchParam)
      .pipe(
        map((response: any) => {
          let data = response.data[0];
          for (let i = 0; i < data.resultList.length; i++) {
            let record = data.resultList[i];
            record.isChecked = false;
            for (let index = 0; index < this.bulkUsers.length; index++) {
              const element = this.bulkUsers[index];
              if (element.userSurrogateId === record.userSurrogateId) {
                record.isChecked = element.isChecked;
                break;
              }
            }
          }
          return response;
        })
      );
  }

  doLogin(credential) {
    let userdate = localStorage.getItem('userData');
    if (userdate) {
      localStorage.removeItem('userData');
    }
    const header = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", "Basic Y2xpZW50SWRQYXNzd29yZDpzZWNyZXQ=");

    const params = new HttpParams()
      .set('client_id', 'clientIdPassword')
      .set('grant_type', 'password')
      .set('username', credential.username)
      .set('password', btoa(credential.password));

    return this.http.post(this.USER_LOGIN_URL, params, { headers: header });
  }

  getUserBySurrogateId(userSurrogateId) {
    return this.http.get<any>(this.USER_LIST_URL + "getUserSurrodateID" + "/" + userSurrogateId);
  }

  doUpdate(obj: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = { headers: headers };
    return this.http.post(this.USER_LIST_URL + "updateUser", obj, options);
  }


  storeUserIdCard(data: any) {
    return this.http.post(this.USER_ID_CARD_STORE_URL, data);
  }

  fetchBulkUsersForDownload() {
    return this.http.get(this.BULK_USERS_URL)
      .pipe(
        map((response: any) => {
          let data = response.data;
          for (let i = 0; i < data.length; i++) {
            let record = data[i];
            record.isChecked = false;
          }
          this.bulkUsers = data;
          return response;
        })
      );
  }

  downloadUsersIDCardsAsZip(userList) {
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'
    };
    return this.http.post(this.BULK_USERS_ID_DOWNLOAD_URL, userList, httpOptions);
  }

  sendMailIDCardToUser(userSurrogateId) {
    return this.http.get(this.BULK_USERS_MAIL_ID_CARD_URL + `/${userSurrogateId}`);
  }

  public get $bulkUsers(): any {
    return this.bulkUsers;
  }

  public set $bulkUsers(value: any) {
    this.bulkUsers = value;
  }
}
