// Modules
import { RouterModule } from '@angular/router';

// Routes
import { RmfComponent } from './rmf.component';
import { UsersComponent } from './users/users.component';
import { RegisterComponent } from './register/register.component';
import { AllowedServicesGuard } from '../shared/guards/allowedServices.guard';
// Constant
import { AllowedServicesConstants as Constant } from '../shared/constants/allowedServices.constant'


export const rmfRoutes = RouterModule.forChild([
  {
    path: '',
    component: RmfComponent,
    children: [
      { path: '', redirectTo: 'users', pathMatch: 'full' },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AllowedServicesGuard],
        data: {
          title: 'Manage Staff/Trainee',
          roles: [Constant.USER_LIST]
        }
      },
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: [AllowedServicesGuard],
        data: {
          title: 'Register Staff/Trainee',
          roles: [Constant.REGISTER]
        }
      },
      {
        path: 'register/:userSurrogateId',
        component: RegisterComponent,
        canActivate: [AllowedServicesGuard],
        data: {
          title: 'Update Staff/Trainee',
          roles: [Constant.REGISTER]
        }
      },
      {
        path: 'report',
        loadChildren: () => import(`./report/report.module`).then(r => r.ReportModule)
      },
    ]
  }
]);