import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarloaderComponent } from './barloader/barloader.component';
import { FullscreenLoaderComponent } from './fullscreenLoader/fullscreenLoader.component';
import { AllowedServicesGuard } from './guards/allowedServices.guard';
import { AllowedServiceDirective } from './directive/allowed-service.directive';
import { OnlynumberDirective } from './directive/number-only.directive'



@NgModule({
  declarations: [BarloaderComponent, FullscreenLoaderComponent, AllowedServiceDirective, OnlynumberDirective],
  imports: [
    CommonModule
  ],
  exports: [BarloaderComponent, FullscreenLoaderComponent, AllowedServiceDirective, OnlynumberDirective],
  providers: [AllowedServicesGuard]
})
export class SharedModule { }
