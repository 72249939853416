import { AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';

export class UserValidator {
    static niNumberValidation(control: FormGroup): ValidationErrors | null {
        console.log("value", (control.value).length);
        if ((control.value).length > 9) {
            return { niNumberValidation: true }
        }
        
        return null;
    }
    static niNumberFormat (control: FormGroup): ValidationErrors | null {
        console.log("value ni",control.value);
        if(control.value.match('[a-zA-Z]{2}[0-9]{6}[a-dA-D]{1}'))
        {
            return { niNumberFormat: true }
        }
        
        return null;
    }

    static cohortValidation(control: AbstractControl) :ValidationErrors | null{
        if (control.value !== null && control.value !== "" && control.value !== undefined) {
            
   
        return { cohortValidation: true }
        }
        return null;
    }
    static PostcodeValidation(control: AbstractControl) {
        if ((control.value).length > 7) {
            return { PostcodeValidation: true }
        }
        return null;
    }
    static collegeValidation(control: AbstractControl) {
        if ((control.value).length > 256) {
            return { collegeValidation: true }
        }
        return null;
    }
    static countryValidation(control: AbstractControl) {
        if ((control.value).length > 128) {
            return { countryValidation: true }
        }
        return null;
    }
    static cityValidation(control: AbstractControl) {
        if ((control.value).length > 45) {
            return { cityValidation: true }
        }
        return null;
    }
    static addressValidation(control: AbstractControl) {
      
        if ((control.value).length > 512) {
            return { addressValidation: true }
        }
      
        return null;
    }
    static spaceValidation(control: AbstractControl) {
        console.log("space check",control.value);
        
        if(control.value === "")
       {
        return { spaceValidation: true }

       }
       if(control.value && control.value.trim().length === 0 )
       {
        return { spaceValidation: true }
       }
       
        // if (control.value.match(/^ *$/) !== null ) {
        //     return { spaceValidation: true }
        // }
        return null;
    }
    static stateValidation(control: AbstractControl) {
        if ((control.value).length > 45) {
            return { stateValidation: true }
        }
        return null;
    }
    static surnameValidation(control: AbstractControl) {
        if ((control.value).length > 128) {
            return { surnameValidation: true }
        }
        return null;
    }
    static nameValidation(control: AbstractControl) {
        if ((control.value).length > 128) {
            return { nameValidation: true }
        }
        return null;
    }
    static workdetailValidation(control: AbstractControl) {
        if ((control.value).length > 512) {
            return { workdetailValidation: true }
        }
        return null;
    }

    static countyValidation(control: AbstractControl) {
        if ((control.value).length > 45) {
            return { countyValidation: true }
        }
        return null;
    }

}
