import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { reportRoutes } from './report.route';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule, BsDatepickerModule, PaginationModule } from 'ngx-bootstrap';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
// Components
import { ReportComponent } from './report.component';
import { LogsReportComponent } from './logs-report/logs-report.component';
import { TraineeScheduleComponent } from './trainee-schedule/trainee-schedule.component';
import { SharedModule } from '../../shared/shared.module';
import { LogHistoryComponent } from './log-history/log-history.component';


@NgModule({
  declarations: [ReportComponent, LogsReportComponent, TraineeScheduleComponent, LogHistoryComponent],
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule,
    BsDatepickerModule,
    PaginationModule,
    reportRoutes,
    SharedModule
  ],
  providers: [DatePipe],
  schemas: [NO_ERRORS_SCHEMA],
  entryComponents: [LogHistoryComponent]
})
export class ReportModule { }
