import { Injectable } from '@angular/core';
import { ToastrService as ToastsManager } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandler {
    constructor(
        public toastr: ToastsManager
    ) { }
  
    showLocalizedMessage(messageKey: string, messageType: string, messageParams?: any[], parentWindowRef?: any) {
        if (messageType === 'error') {
            this.toastr.error(messageKey, '', { timeOut: 8000 });
            // let errorAudio = <HTMLAudioElement>document.getElementById("errorAudio");
            // errorAudio.play();
        } else if (messageType === 'success')
            this.toastr.success(messageKey, '', { timeOut: 8000 });
        else {
            this.toastr.info(messageKey, '');
        }
    }
}