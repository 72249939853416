import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { WebcamModule } from 'ngx-webcam';
import { QRCodeModule } from 'angularx-qrcode';
import { RmfModule } from './rmf/rmf.module';
import { ToastrModule } from 'ngx-toastr';
// components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
//import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AuthInterceptor } from './intercepter/auth.intercepter';
import { ErrorInterceptor } from './intercepter/error.intercepter';
import { RestrictLoginWhileSessionExistGuard } from './shared/guards/restrictLoginWhileSessionExist.guard';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    WebcamModule,
    FormsModule,
    ReactiveFormsModule,
    // AngularFontAwesomeModule,
    QRCodeModule,
    RmfModule,
    SharedModule,
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    RestrictLoginWhileSessionExistGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
