import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ErrorHandler } from '../../../services/errorhandling';
import { ReportService } from '../../../services/report.service';

import { formatDate } from '@angular/common';

@Component({
  selector: 'app-trainee-schedule',
  templateUrl: './trainee-schedule.component.html',
  styleUrls: ['./trainee-schedule.component.scss']
})
export class TraineeScheduleComponent implements OnInit {

  constructor(private userService: UserService, private reportService: ReportService,
    public errorHandler: ErrorHandler) {
    this.pageLoad = true;
  }

  public usersSchedules: any = {};
  public isLoading: boolean = false;
  public searchQuery: string = "";
  public pageNo: number = 1;
  public pageSize: number = 10;

  public location = "0";
  public disableLocationDD: boolean = false;
  public locationList = [];

  public date: Date = null;
  public pageLoad: boolean = false;

  ngOnInit() {
    this.getLocations();
    setTimeout(() => {
      this.fetchSchedules();
      this.pageLoad = false;
    }, 1000);
  }

  locationChange() {
    this.fetchSchedules();
  }

  dateChange(event) {
    if (event && !this.pageLoad) {
      this.date = event;
      this.pageNo = 1;
      this.fetchSchedules();
    }
  }
  clearDateRange() {
    if (null != this.date) {
      this.date = null;
      this.pageNo = 1;
      this.fetchSchedules();
    }
  }

  fetchSchedules() {
    this.isLoading = true;
    let filterParams = { filters: { user: '' } };
    if (this.searchQuery)
      filterParams['filters']['user'] = this.searchQuery;

    if (this.location && this.location !== "0")
      filterParams['filters']['location'] = this.location;

    if (this.date)
      filterParams['filters']['fromDate'] = formatDate(this.date, 'yyyy-MM-dd', 'en_US');

    this.userService.fetchTraineeSchedule(this.pageNo, this.pageSize, filterParams).subscribe(
      res => {
        console.log("res", res);
        if (res.status === 200) {
          this.usersSchedules = res.data[0];
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }
      },
      err => {
        this.isLoading = false;
        this.errorHandler.showLocalizedMessage(err['errors'].errorMessage, 'error');
      }
    );
  }

  exportExcelTrainee(event) {
    event.stopPropagation();
    event.preventDefault();
    let filterParams = { filters: { user: '' } };
    if (this.searchQuery)
      filterParams['filters']['user'] = this.searchQuery;

    if (this.location && this.location !== "0")
      filterParams['filters']['location'] = this.location;

    if (this.date)
      filterParams['filters']['fromDate'] = formatDate(this.date, 'yyyy-MM-dd', 'en_US');

    this.reportService.exportTraineeReport(this.pageNo, this.pageSize, filterParams).subscribe(
      res => {
        var file = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'TraineeSchedule.xlsx';
        document.body.appendChild(a);
        a.click();

      },
      err => {

      }
    );
  }

  searchByName() {

    this.fetchSchedules();

  }

  clearName() {
    if (this.searchQuery != "") {
      this.searchQuery = "";
      this.pageNo = 1;
      this.fetchSchedules();
    }
  }

  public pageChanged(event: any): void {
    this.pageNo = event.page;
    this.pageSize = event.itemsPerPage;
    this.fetchSchedules();
    window.scrollTo(0, 0);
  }

  getLocations() {
    let userLocation = JSON.parse(localStorage.getItem('userData')).location || '';
    this.reportService.getLocations().subscribe(
      (res) => {
        if (res.status === 200) {
          this.locationList = res.data;
          this.locationList.forEach((loacation: any) => {
            if (loacation.name === userLocation) {
              this.disableLocationDD = true;
              this.location = loacation.name;
            }
          });
        } else {
          console.error('No locations');
        }
      }, (err) => {

      }
    );
  }
}
