import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import html2canvas from 'html2canvas';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-print-qr',
  templateUrl: './print-qr.component.html',
  styleUrls: ['./print-qr.component.scss']
})
export class PrintQrComponent implements OnInit {

  @Input() modelData: any;
  @Output() action = new EventEmitter();
  public idCardBase64URL: any;

  constructor(public bsModalRef: BsModalRef,
    private userService: UserService) { }

  ngOnInit() {
    this.storeIDCardAtServer(this.modelData);
  }

  storeIDCardAtServer(user) {
    let _self = this;
    let html = _self.buildIDcardHTML(user)

    var iframe = document.createElement('iframe');
    iframe.style.visibility = 'hidden';
    document.body.appendChild(iframe);
    var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    iframedoc.body.innerHTML = html;

    html2canvas(iframedoc.body, { height: 460, width: 720 }).then(canvas => {
      _self.idCardBase64URL = canvas.toDataURL("image/png");

      // let data = {
      //   userSurrogateId: _self.modelData.userSurrogateId,
      //   base64: _self.idCardBase64URL.replace('data:image/png;base64,', '')
      // }
      // Calling api to store ID card at backend
      // this.userService.storeUserIdCard(data).subscribe(
      //   res => {
      //     console.log('User ID card stored.', res);
      //   },
      //   err => {
      //     console.log('User id not stored.');
      //   }
      // );
      document.querySelectorAll('iframe').forEach(
        function (elem) {
          elem.parentNode.removeChild(elem);
        });
    });
  }


  skip() {
    this.action.emit('skip');
  }

  downloadCard(event) {
    if (event.stopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }
    var a = document.createElement('a');
    a.href = this.idCardBase64URL;
    a.download = 'IdCard.png';
    a.click();
    setTimeout(() => {
      this.action.emit('print-qr');
    }, 100);
  }

  private buildIDcardHTML(user: any): string {
    let html = `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>Idcard</title>
          <style>
          //Application fonts

      @font-face {
        font-family: 'charlie_textlight';
        src:
          url('../../../assets/fonts/charlie_text-light-webfont.woff2') format('woff2'),
          url('../../../assets/fonts/charlie_text-light-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: 'charlie_textregular';
        src:
          url('../../../assets/fonts/charlie_text-regular-webfont.woff2') format('woff2'),
          url('../../../assets/fonts/charlie_text-regular-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: 'charlie_textsemibold';
        src:
          url('../../../assets/fonts/charlie_text-semibold-webfont.woff2') format('woff2'),
          url('../../../assets/fonts/charlie_text-semibold-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
      }

      * {
        -webkit-font-smoothing: antialiased;
      }

      body {
        margin: 0;
        font-family: "charlie_textregular", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #3c495a;
        text-align: left;
        background-color: #fbfdff; 
      }
      .id-card-wrapper {
        margin : 10px;
        display: flex;
        height : 100%
      }
      .id-card {
        flex-basis: 100%;
        width: 700px;
        height: 430px;
        margin: auto;
        border: 1px solid #b8bec3;
        border-radius: 5px;
      }
      .header-row .dp {
        flex-basis: 13.3%;
        position: relative;
        align-self: center;
        margin-left: 15px;
        margin-right: 15px;
      }
      .header-row .desc {
        flex-basis: 85.3%;
        padding: 31px;
        padding-left: 0;
      }
      .logo{
          width:120px;
          margin:0; 
          height:80px;
      }
      .header-text {
          display:table-cell;
          vertical-align:middle;
          font-size: 1.5rem;
          color: #000073;
      }
      .profile-row {
        display: flex;
      }
      
      .profile-row .dp {
        flex-basis: 50%;
        width: 350px;
        margin-left: 15px;
        align-self: center;
        margin: 0 auto;
      }
      
      .profile-row .desc {
        text-align: center;
      }

      .profile-row .qrcode {
        flex-basis: 50%;
        margin: auto;
        width: 350px;
      }

      .profile-row .qrcode img {
        width: 325px;
        display: block;
        height: 325px;
      }

      .header-row {
        display: flex;
        border-bottom: 1px solid #dee2e6;
      }
    
      .mh_lh{
          max-height: 14px;
          line-height: 14px;
      }
      .brand-secondary {
          color: #27e1ba; 
      }
      .budicon-phone{
          width:25px;
          margin:0; 
          height:25px;
          margin-left: -9px;
      }
      .container {
        margin: 0 auto;
        min-width: 150px;
        width: 150px;
        height: 150px;
        background-image: url("`+ user.profilePath + `");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-radius: 50%;
      }
      </style>
      </head>
      <body>
          <div class="id-card-wrapper">
              <div class="id-card">
                <div class="header-row">
                    <div class="dp">
                      <img class="logo" src="../../../assets/images/RMFGroup.jpg" alt="profile image">
                    </div>
                    <div class="desc">
                      <span class="header-text">RMF Construction Training Academy LTD</span>
                    </div>
                </div>
                <div class="profile-row">
                  <div class="dp">
                    <div class="container"></div>
                    <div class="desc">
                      <span style="font-size: 1.5rem;">`+ user.name + ` ` + user.surname + `</span>
                    </div>
                  </div>
                  <div class="qrcode">
                    <img src="`+ user.qrCodePath + `">
                  </div>
                </div>
              </div>
            </div>
      </body>
      </html>`;

    // <div style="display: flex; align-items: center; justify-content: left;margin-top:5px;" >
    //   <img class="budicon-phone" src = "../../../assets/images/budicon-phone.png" alt = "profile image" >
    //     <span style="font-size: 1.25rem;" > 0`+ user.mobileNumber + ` < /span> 
    //       < /div>
    return html;
  }
}
