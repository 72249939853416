import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// components
import { LoginComponent } from '../app/login/login.component';
import { RmfComponent } from './rmf/rmf.component';
import { RestrictLoginWhileSessionExistGuard } from './shared/guards/restrictLoginWhileSessionExist.guard';


const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
    canActivate: [RestrictLoginWhileSessionExistGuard]
  },
  {
    path: 'rmf',
    component: RmfComponent,
    loadChildren: "./rmf/rmf.module#RmfModule"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
