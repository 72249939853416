import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private LOG_REPORT_URL = '/RMFApi/report/';
  private LOCATION_LIST = "/RMFApi/user/locationlist";
  private TRAINEE_LIST_URL="/RMFApi/report/traineeList";

  constructor(private http: HttpClient) { }

  fetchlogReport(pageNo: number, pageSize: number, searchParam: any): any {
    return this.http.post(this.LOG_REPORT_URL + pageNo + '/' + pageSize, searchParam);
  }

  exportlogReport(searchParam: any): any {
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'
    };
    return this.http.post(this.LOG_REPORT_URL + '/download', searchParam, httpOptions);
  }


  exportTraineeReport(pageNo:any,pageSize:any,searchParam: any): any {
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'
    };
    return this.http.post(this.TRAINEE_LIST_URL+"/"+pageNo+"/"+ pageSize,searchParam,httpOptions);
  }
  getLocations() {
    return this.http.get<any>(this.LOCATION_LIST);
  }
}
