import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ErrorHandler } from '../services/errorhandling'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loading: boolean = false;
  public credential = { username: "", password: "" };
  constructor(private router: Router,
    private userService: UserService,
    private errorHandler: ErrorHandler) { }

  ngOnInit() {
    let userdate = localStorage.getItem('userData');
    if (userdate) {
      localStorage.removeItem('userData');
    }
  }

  login() {
    this.loading = true;
    this.userService.doLogin(this.credential).subscribe((response) => {
      this.loading = false;
      localStorage.setItem('userData', JSON.stringify(response));
      this.router.navigateByUrl("/report/logs");
    }, (error) => {
      if (error.status === 400) {
        this.errorHandler.showLocalizedMessage("Invalid Username/Password", 'error');
      }
      this.loading = false;
    });
  }

}
