import { Injectable } from '@angular/core';

@Injectable({
	providedIn:'root'
})
export class LoaderService {
    public showLoader: boolean = false;

    constructor() { }


	public get $showLoader(): boolean  {
		return this.showLoader;
	}

	public set $showLoader(value: boolean ) {
		this.showLoader = value;
	}


}