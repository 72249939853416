import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-rmf',
  templateUrl: './rmf.component.html',
  styleUrls: ['./rmf.component.css']
})
export class RmfComponent implements OnInit {


  public pageTitle: string;
  mySubscription: any;
  userRole: any;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.userRole = JSON.parse(localStorage.getItem('userData')).userRole || '';

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        return this.getPageTitle();
      })
    ).subscribe((pageTitle: string | null) => {
      // Trick the Router into believing it's last link wasn't previously loaded
      this.router.navigated = false;
      console.log("pageTitel", pageTitle);
      if (this.userRole && this.userRole === 'ROLE_TRAINEE_ADMIN') {
        if (pageTitle == 'Register Staff/Trainee') {
          this.pageTitle = 'Register Trainee';

        } else if (pageTitle == 'Manage Staff/Trainee') {
          this.pageTitle = 'Manage Trainee';
        }
        else if (pageTitle == 'Update Staff/Trainee') {
          this.pageTitle = 'Update Trainee';

        } else {
          this.pageTitle = pageTitle;
        }
      } else {
        this.pageTitle = pageTitle;
      }
    });
  }


  ngOnInit() {
    this.mySubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      map(() => {
        return this.getPageTitle();
      }),
    )
      .subscribe((pageTitle: string | null) => {
        if (this.userRole && this.userRole === 'ROLE_TRAINEE_ADMIN') {
          if (pageTitle == 'Register Staff/Trainee') {
            this.pageTitle = 'Register Trainee';
  
          } else if (pageTitle == 'Manage Staff/Trainee') {
            this.pageTitle = 'Manage Trainee';
          }
          else if (pageTitle == 'Update Staff/Trainee') {
            this.pageTitle = 'Update Trainee';
  
          } else {
            this.pageTitle = pageTitle;
          }
        } else {
          this.pageTitle = pageTitle;
        }
      });
    // this.pageTitle = this.getPageTitle();
  }

  getPageTitle(): string | null {
    let child = this.activatedRoute.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data['title']) {
        return child.snapshot.data['title'];
      } else {
        return null;
      }
    }
    return null;
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
