// Modules
import { RouterModule } from '@angular/router';
//Components
import { ReportComponent } from './report.component';
import { LogsReportComponent } from './logs-report/logs-report.component';
import { TraineeScheduleComponent } from './trainee-schedule/trainee-schedule.component';
//Services
import { AllowedServicesGuard } from '../../shared/guards/allowedServices.guard';

// Constant
import { AllowedServicesConstants as Constant } from '../../shared/constants/allowedServices.constant'

export const reportRoutes = RouterModule.forChild([
    {
        path: '',
        component: ReportComponent,
        children: [
            {
                path: 'logs',
                component: LogsReportComponent,
                canActivate: [AllowedServicesGuard],
                data: {
                    title: 'Report',
                    roles: [Constant.REPORT]
                }
            },
            {
                path: 'traineeSchedule',
                component: TraineeScheduleComponent,
                canActivate: [AllowedServicesGuard],
                data: {
                    title: 'Trainee Schedule',
                    roles: [Constant.TRAINEE_SCHEDULE]
                }
            }
        ]
    }
]);