export class AllowedServicesConstants {
    static readonly LOGIN: string = 'login';
    static readonly REGISTER: string = 'register';
    static readonly REGISTER_UPDATE: string = 'register_update';
    static readonly USER_LIST: string = 'userlist';
    static readonly REPORT: string = 'report';
    static readonly PRINT_CARD: string = 'printcard';
    static readonly DOWNLOAD_BULK: string = 'download_bulk';
    static readonly TRAINEE_SCHEDULE: string = 'traineeSchedule';

}