import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient} from '@angular/common/http';
import{DatePipe} from '@angular/common'



@Component({
  selector: 'app-coursepopup',
  templateUrl: './coursepopup.component.html',
  styleUrls: ['./coursepopup.component.scss']
})
export class CoursepopupComponent implements OnInit {
  @Output() courselist = new EventEmitter();
  @Output() flag = new EventEmitter();
  courseForm:FormGroup;
  courseList:any;
  list:any;
  listOfCourse:any=[];
  courseDetail:any;
  today:Date;
  minDate:Date;
  disable:boolean=false;
  errorMsg:string;

  constructor(private formBuilder:FormBuilder,public bsModalRef: BsModalRef,public http:HttpClient,public datePipe: DatePipe) { 
    this.courseForm=this.formBuilder.group(
      {
        courseName :['',  Validators.required],

       startDate :['',  Validators.required],
       endDate :['',  Validators.required],
       location : ['',  Validators.required],
      }
    );

    this.http.get<any>("/RMFApi/user/courselist").subscribe((res) => {
      console.log("category list", res);
      this.courseList = res.data;
    }, (error) => { console.log("error in getting problem type", error) });


    this.http.get<any>("/RMFApi/user/locationlist").subscribe((res) => {
      console.log("category list", res);
      this.list = res.data;
    }, (error) => { console.log("error in getting problem type", error) });


    this.today = new Date();
    this.minDate = new Date();
  }

  ngOnInit() {
   
  }


  saveCourse(courseForm:any,flag:any)
  {
    if(courseForm.startDate <= courseForm.endDate)
    {
    this.courseForm.valid;
    }
    else{
     this. errorMsg="Start date should not be less than end date";
     this.courseForm.invalid;
return false;
    }
 console.log("course information",courseForm,flag);
 if(flag === 'N')
 {
   this.courseDetail=courseForm;
   this.courseDetail.dateOfRegistration=new Date();
 
   this.listOfCourse.push(this.courseDetail);
   this.courselist.emit(this.courseDetail);
   this.flag.emit("N");

 }
 else{
   this.errorMsg=null;
   this.courseDetail=courseForm;
   this.courseDetail.dateOfRegistration=new Date();
   this.listOfCourse.push(this.courseDetail);
   console.log("list of course",this.listOfCourse);
   this.courselist.emit(this.courseDetail);
   this.flag.emit("Y");
   this.courseForm.reset();



 }
  }
  close()
  {
    this.flag.emit("close");
  }

 
}
