import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WebcamImage } from 'ngx-webcam';
import { Router, ActivatedRoute } from '@angular/router';
// components
import { PrintQrComponent } from '../print-qr/print-qr.component';
import { CoursepopupComponent } from '../coursepopup/coursepopup.component';
import { ErrorHandler } from '../../services/errorhandling';
import { ReportService } from '../../services/report.service';
import { UserService } from '../../services/user.service';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { LoaderService } from '../../services/loader.service';
import { UserValidator } from '../Validators/user-validator';
import html2canvas from 'html2canvas';




@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  // modal variables
  bsModalRef: BsModalRef;

  registrationForm: FormGroup;
  courseForm: FormGroup;
  showWebcam = false;
  private trigger: Subject<void> = new Subject<void>();
  public webcamImgBase64: string = null;
  newCourse: String;
  listOfCourse = [];
  list: any;
  courseList: any;
  courseDetail: any;
  addCoursebutton = true;
  registerFlag = true;
  AllowCourse = false;
  user: any
  obj: Object;
  YES: any = 0;
  NO: any = 0;
  updateObj: Object;
  userSurrogateId: string;
  isLoading: boolean = false;
  today: Date;
  public base64: any;

  // following variables for image capture scenario
  imageChangeEdit = false;
  defaultImageShow: boolean = true;
  webcamShow: boolean = false;
  captureImageShow: boolean = false;
  staffFlag: boolean = true;
  userRole:any;
  disableUserType:boolean=false;

  constructor(private formBuilder: FormBuilder, private loaderService: LoaderService, private datePipe: DatePipe, private modalService: BsModalService, private router: Router, private reportService: ReportService, public errorHandler: ErrorHandler, public userService: UserService, public http: HttpClient, private route: ActivatedRoute, ) {
    this.userRole = JSON.parse(localStorage.getItem('userData')).userRole || '';
   
    this.registrationForm = this.formBuilder.group(
      {

        name: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z_ ]*$'), UserValidator.nameValidation])],
        surname: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z_ ]*$'), UserValidator.surnameValidation])],
        DOB: ['', Validators.required],
        userType: ['', Validators.required],
        niNumber: ['', Validators.compose([Validators.required, Validators.pattern("[a-zA-Z]{2}[0-9]{6}[a-dA-D]{1}")])],

        email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(1)])],

        contactNumber: ['', Validators.compose([Validators.required, Validators.pattern('0(?!(0))[0-9]{10}$')])],
        address: ['', Validators.compose([Validators.required, UserValidator.spaceValidation, UserValidator.addressValidation])],
        address1: [''],

        city: [''],
        county: [''],

        // state: ['', Validators.compose([Validators.pattern(".*\\S.*[a-zA-z0-9 ]"), UserValidator.stateValidation])],
        // country: ['', Validators.compose([Validators.required, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")])],
        postcode: ['', Validators.compose([Validators.required, UserValidator.PostcodeValidation, Validators.pattern(".*\\S.*[a-zA-z0-9 ]")])],
        college: ['', Validators.compose([Validators.pattern(".*\\S.*[a-zA-z0-9 ]"), UserValidator.collegeValidation])],
        cohort: ['', Validators.compose([Validators.required, UserValidator.spaceValidation])],
        //cohort: ['', Validators.required],

        working: ['2'],

        workingDetails: [''],

        drivingLicence: ['', Validators.required],
        carRegisterNumber: ['']


      }
    );

    this.courseForm = this.formBuilder.group({
      course: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      dateOfReg: ['', Validators.required],
      location: ['', Validators.required]
    }
    );
    this.today = new Date();
    if(this.userRole &&  this.userRole === 'ROLE_TRAINEE_ADMIN')
    {
      this.checkType(this.registrationForm.get('userType').patchValue(1));
      this.disableUserType=true;
    }else
    {
      this.disableUserType=false;
    }
  }

  ngOnInit() {
    

    this.route.params.subscribe(params => {
      if (params['userSurrogateId']) {
        this.userSurrogateId = params['userSurrogateId'];
        this.registerFlag = false;
        this.loaderService.$showLoader = true;
        this.userService.getUserBySurrogateId(params['userSurrogateId']).subscribe(
          data => {
            if (data['status'] === 200) {
              this.user = data;
              console.log("this.user", this.user);
              const details = this.user['data'][0];
              this.registrationForm.get('name').patchValue(details['name']);
              this.registrationForm.get('surname').patchValue(details['surname']);
              this.registrationForm.get('DOB').patchValue(new Date(details['userDetails'].dob));
              this.registrationForm.get('userType').patchValue(details['userType']);
              this.checkType(details['userType'] === 1 ? 'Trainee' : 'Staff');

              this.registrationForm.get('niNumber').patchValue(details['userDetails'].niNumber);
              this.registrationForm.get('email').patchValue(details['email']);
              this.registrationForm.get('contactNumber').patchValue(0 + "" + details['mobileNumber']);
              this.registrationForm.get('address').patchValue(details['userAddress'].addressLine1);
              if (details['userAddress'].addressLine2 !== null) {
                this.registrationForm.get('address1').patchValue(details['userAddress'].addressLine2);
              }
              this.registrationForm.get('city').patchValue(details['userAddress'].city);

              // this.registrationForm.get('country').patchValue(details['userAddress'].country);
              // if (details['userAddress'].state !== null) {
              //   this.registrationForm.get('state').patchValue(details['userAddress'].state);
              // }
              if (details['userAddress'].county !== null) {
                this.registrationForm.get('county').patchValue(details['userAddress'].county);
              }
              this.registrationForm.get('postcode').patchValue(details['userAddress'].postcode);
              this.registrationForm.get('college').patchValue(details['userDetails'].college);
              if (details['userDetails'].cohort !== null && details['userDetails'].cohort !== "") {
                this.registrationForm.get('cohort').setValidators([Validators.required, UserValidator.spaceValidation]);
                this.registrationForm.get('cohort').patchValue(details['userDetails'].cohort);
              } else {
                this.registrationForm.get('cohort').setValidators(null);
                this.registrationForm.get('cohort').setErrors(null);
                this.registrationForm.get('cohort').patchValue(null);




              }
              this.registrationForm.get('working').patchValue(details['userDetails'].isWorking);
              this.registrationForm.get('workingDetails').patchValue(details['userDetails'].workingDetails);
              this.registrationForm.get('drivingLicence').patchValue(details['userDetails'].isDrivingLicense);
              this.registrationForm.get('carRegisterNumber').patchValue(details['userDetails'].carRegisterNumber);

              this.listOfCourse = details['userCourses'];
              if (this.listOfCourse.length > 0) {
                this.addCoursebutton = false;
              }
              this.webcamImgBase64 = details['userDetails'].imageRelativePath;
              this.webcamShow = false;
              this.captureImageShow = true;
              this.imageChangeEdit = false;
              this.defaultImageShow = false;
              this.loaderService.$showLoader = false;
            } else if (data['status'] === 500) {
              this.errorHandler.showLocalizedMessage('Failed to fetch user details,Please try again.', 'error');
              this.loaderService.$showLoader = false;
            }
          }, err => {
            console.log(err);
            this.loaderService.$showLoader = false;
          });
      }
    });
  }

  captureImage(webcamImage: WebcamImage): void {
    if (!this.registerFlag) {
      this.imageChangeEdit = true;
    }
    this.webcamImgBase64 = (webcamImage.imageAsDataUrl);
    this.base64 = (webcamImage.imageAsBase64);
    this.webcamShow = false;
    this.captureImageShow = true;
    this.defaultImageShow = false;
  }

  public triggerSnapshot(): void {
    this.trigger.next();

  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  showWebcamera() {
    this.webcamShow = true;
    this.captureImageShow = false;
    this.defaultImageShow = false;
  }
  clearImage() {
    this.webcamImgBase64 = "";
    this.webcamShow = false;
    this.captureImageShow = false;
    this.defaultImageShow = true;
    this.base64 = "";
  }

  onchange(event) {
    event.preventDefault();
    // let self=this;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      let imageSrc = reader.result as string;
      this.webcamImgBase64 = imageSrc;
      let base = imageSrc.split(',');
      this.base64 = base[1];
      this.webcamShow = false;
      this.captureImageShow = true;
      this.defaultImageShow = false;
      this.imageChangeEdit = true;
    }
  }

  AddCourse(id: string) {
    this.newCourse = id;
    this.courseForm.reset();
    this.bsModalRef = this.modalService.show(CoursepopupComponent, { backdrop: 'static', class: 'modal-dialog-centered' });
    this.bsModalRef.setClass('modal-sm');

    this.bsModalRef.content.flag.subscribe((flag) => {
      if (flag === 'close') {
        this.bsModalRef.hide();
        this.addCoursebutton = true;

        if (this.listOfCourse.length > 0) {
          this.addCoursebutton = false;


        }
      } else if (flag === 'N') {
        console.log("registration coures", this.bsModalRef['content'].listOfCourse);

        this.listOfCourse.push(this.bsModalRef['content'].courseDetail);
        this.bsModalRef.hide();
      } else {
        this.listOfCourse.push(this.bsModalRef['content'].courseDetail);
      }
    });
    console.log("after push list of course", this.listOfCourse);
    this.addCoursebutton = false;

  }



  register(registerdtls: any, coursedtls: any, webcamImgBase64: any, event) {
    let msg = '';
    if (event.stopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }
    msg = this.validationMsg(coursedtls, webcamImgBase64, registerdtls);
    if (msg === "success") {
      this.obj = {
        name: registerdtls.name,
        surname: registerdtls.surname,
        email: registerdtls.email,
        mobileNumber: registerdtls.contactNumber,
        userType: parseInt(registerdtls.userType),
        base64: this.base64,
        userDetails: {
          dob: registerdtls.DOB,
          niNumber: registerdtls.niNumber,
          college: registerdtls.college,
          cohort: registerdtls.cohort,
          isWorking: registerdtls.working,
          workingDetails: registerdtls.workingDetails,
          isDrivingLicense: registerdtls.drivingLicence,
          carRegisterNumber: registerdtls.carRegisterNumber


        },
        userAddresses: {
          addressLine1: registerdtls.address,
          addressLine2: registerdtls.address1,
          city: registerdtls.city,
          // country: registerdtls.country
          // state: registerdtls.state,
          county: registerdtls.county,
          postcode: registerdtls.postcode
        },
        userCourses: (registerdtls.userType === 1 ? coursedtls : [])
      }

      this.loaderService.$showLoader = true;
      this.userService.doRegister(this.obj).subscribe(res => {
        if (res['status'] === 200) {
          window.scrollTo(0, 0);
          const details = res['data'][0];
          // Store user ID card at server
          details.profileImageBase64 = 'data:image/jpeg;base64,' + details.profileImageBase64;
          details.qrcodeBase64 = 'data:image/jpeg;base64,' + details.qrcodeBase64;
          this.storeIDCardAtServer(details, 'register');
          // Sending email to user with ID card attachement.
          // setTimeout(() => {
          //   this.userService.sendMailIDCardToUser(details.userSurrogateId).subscribe((res: any) => {
          //     console.log(res.data[0]);
          //   });
          // }, 3000);
        } else if (res['status'] === 500) {
          this.loaderService.$showLoader = false;
          this.errorHandler.showLocalizedMessage(res['errors'].errorMessage, 'error');
        }
      }, err => {
        this.loaderService.$showLoader = false;
        this.errorHandler.showLocalizedMessage(err, 'error');
      });
    } else {
      this.errorHandler.showLocalizedMessage(msg, 'error');
      console.log("msg", msg);
    }
  }

  private openQRCodePopup(details: any) {
    let modelData = {};
    modelData['userSurrogateId'] = details.userSurrogateId;
    modelData['name'] = details.name;
    modelData['surname'] = details.surname;
    modelData['profilePath'] = details.profileImageBase64;
    modelData['qrCodePath'] = details.qrcodeBase64;
    modelData['mobileNumber'] = details.mobileNumber;
    modelData['niNumber'] = details.userDetails.niNumber;
    const initialState = {
      modelData: modelData
    };
    this.bsModalRef = this.modalService.show(PrintQrComponent, { backdrop: 'static', class: 'modal-dialog-centered', initialState });
    this.bsModalRef.content.action
      .subscribe((value) => {
        console.log(value);
        this.bsModalRef.hide();
        this.errorHandler.showLocalizedMessage('User created successfully.', 'success');
        //this.router.navigateByUrl("/users");
        setTimeout(() => {
          window.location.href = window.location.protocol + '//' + window.location.host + '/users';
        }, 100);

      }, (err) => {
        console.log("error occured while closing popup");
        this.bsModalRef.hide();
        return false;
      });
  }

  deleteCourse(index: any, courseDetail: any) {
    let removed = this.listOfCourse.splice(index, 1);
    console.log("after removed array is ", this.listOfCourse);
    console.log("removed element", removed);
    if (this.listOfCourse.length === 0) {
      this.addCoursebutton = true;

    }
  }

  public validationMsg(coursedtls: any, webcamImgBase64: any, registerdtls: any): string {
    let returnMessage = "success";
    if (webcamImgBase64 === "" || webcamImgBase64 === null) {
      return returnMessage = "Profile picture required.";
    }
    if (registerdtls.userType === 1 && coursedtls && coursedtls.length === 0) {
      return "Course information required.";
    }
    return returnMessage;
  }

  update(ev: any, registerdtls: any, coursedtls: any) {
    let msg = 'success';
    msg = this.validationMsg(coursedtls, this.webcamImgBase64, registerdtls);
    if (msg === "success") {
      this.updateObj = {
        userSurrogateId: this.userSurrogateId,
        name: registerdtls.name,
        surname: registerdtls.surname,
        email: registerdtls.email,
        mobileNumber: registerdtls.contactNumber,
        userType: parseInt(registerdtls.userType),
        base64: this.base64,
        userDetails: {
          dob: registerdtls.DOB,
          niNumber: registerdtls.niNumber,
          college: registerdtls.college,
          cohort: registerdtls.cohort,
          isWorking: registerdtls.working,
          workingDetails: registerdtls.workingDetails,
          isDrivingLicense: registerdtls.drivingLicence,
          carRegisterNumber: registerdtls.carRegisterNumber
        },
        userAddresses: {
          addressLine1: registerdtls.address,
          addressLine2: registerdtls.address1,
          city: registerdtls.city,
          // country: registerdtls.country,
          // state: registerdtls.state,
          county: registerdtls.county,
          postcode: registerdtls.postcode
        },
        userCourses: (registerdtls.userType === 1 ? coursedtls : [])
      }
      this.loaderService.$showLoader = true;
      this.userService.doUpdate(this.updateObj).subscribe((status) => {
        if (status['status'] === 200) {
          const details = status['data'][0];
          this.errorHandler.showLocalizedMessage("User Updated Successfully", 'success');
          // Store user ID card at server
          details.profileImageBase64 = 'data:image/jpeg;base64,' + details.profileImageBase64;
          details.qrcodeBase64 = 'data:image/jpeg;base64,' + details.qrcodeBase64;
          this.storeIDCardAtServer(details, 'update');
          this.listOfCourse = null;
          //this.router.navigateByUrl("/users");
        } else if (status['status'] === 500) {
          this.loaderService.$showLoader = false;
          this.errorHandler.showLocalizedMessage(status['errors'].errorMessage, 'error');
          console.log("msg", status['errors'].errorMessage);
        }
      }, err => {
        this.loaderService.$showLoader = false;
        this.errorHandler.showLocalizedMessage(err, 'error');
      });
    } else {
      this.errorHandler.showLocalizedMessage(msg, 'error');
    }
  }


  cancel() {
    this.router.navigateByUrl("users");
  }

  checkType(userType) {
    if (userType === "Staff") {
      this.AllowCourse = false;
      this.staffFlag = false;
      // this.registrationForm.get('working').setValidators(null);
      this.registrationForm.get('working').patchValue('2');
      this.registrationForm.get('workingDetails').patchValue('');
      this.registrationForm.get('college').patchValue('');
      // this.registrationForm.get('cohort').patchValue('');
      this.registrationForm.get('cohort').patchValue(null);
      this.registrationForm.get('cohort').setValidators(null);
      this.registrationForm.get('cohort').setErrors(null);
    } else {
      this.registrationForm.get('cohort').setValidators([Validators.required, UserValidator.spaceValidation]);
      this.registrationForm.get('cohort').patchValue(null);
      this.AllowCourse = true;
      this.staffFlag = true;
    }
  }

  storeIDCardAtServer(user, flag) {
    let html = this.buildIDcardHTML(user)

    var iframe = document.createElement('iframe');
    iframe.style.visibility = 'hidden';
    document.body.appendChild(iframe);
    var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    iframedoc.body.innerHTML = html;

    html2canvas(iframedoc.body, { height: 460, width: 720 }).then(canvas => {
      let idCardBase64URL = canvas.toDataURL("image/png");
      let data = {
        userSurrogateId: user.userSurrogateId,
        base64: idCardBase64URL.replace('data:image/png;base64,', '')
      }
      this.userService.storeUserIdCard(data).subscribe(
        res => {
          this.loaderService.$showLoader = false;
          console.log('User ID card stored.', res);
          if (flag === 'update') {
            window.location.href = window.location.protocol + '//' + window.location.host + '/users';
          }
        },
        err => {
          this.loaderService.$showLoader = false;
          this.errorHandler.showLocalizedMessage("Error occured while updating user ID card.", 'error');
        }
      );
      document.querySelectorAll('iframe').forEach(
        function (elem) {
          elem.parentNode.removeChild(elem);
        });
      if (flag === 'register') {
        this.openQRCodePopup(user);
      }
    });
  }

  private buildIDcardHTML(user: any): string {
    let html = `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>Idcard</title>
          <style>
          //Application fonts

      @font-face {
        font-family: 'charlie_textlight';
        src:
          url('../../../assets/fonts/charlie_text-light-webfont.woff2') format('woff2'),
          url('../../../assets/fonts/charlie_text-light-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: 'charlie_textregular';
        src:
          url('../../../assets/fonts/charlie_text-regular-webfont.woff2') format('woff2'),
          url('../../../assets/fonts/charlie_text-regular-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: 'charlie_textsemibold';
        src:
          url('../../../assets/fonts/charlie_text-semibold-webfont.woff2') format('woff2'),
          url('../../../assets/fonts/charlie_text-semibold-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
      }

      * {
        -webkit-font-smoothing: antialiased;
      }

       body {
        margin: 0;
        font-family: "charlie_textregular", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #3c495a;
        text-align: left;
        background-color: #fbfdff; 
      }
      .id-card-wrapper {
        margin : 10px;
        display: flex;
        height : 100%
      }
      .id-card {
        flex-basis: 100%;
        width: 700px;
        height: 430px;
        margin: auto;
        border: 1px solid #b8bec3;
        border-radius: 5px;
      }
      .header-row .dp {
        flex-basis: 13.3%;
        position: relative;
        align-self: center;
        margin-left: 15px;
        margin-right: 15px;
      }
      .header-row .desc {
        flex-basis: 85.3%;
        padding: 31px;
        padding-left: 0;
      }
      .logo{
          width:120px;
          margin:0; 
          height:80px;
      }
      .header-text {
          display:table-cell;
          vertical-align:middle;
          font-size: 1.5rem;
          color: #000073;
      }
      .profile-row {
        display: flex;
      }
      
      .profile-row .dp {
        flex-basis: 50%;
        width: 350px;
        margin-left: 15px;
        align-self: center;
        margin: 0 auto;
      }
      
      .profile-row .desc {
        text-align: center;
      }

      .profile-row .qrcode {
        flex-basis: 50%;
        margin: auto;
        width: 350px;
      }

      .profile-row .qrcode img {
        width: 325px;
        display: block;
        height: 325px;
      }

      .header-row {
        display: flex;
        border-bottom: 1px solid #dee2e6;
      }
    
      .mh_lh{
          max-height: 14px;
          line-height: 14px;
      }
      .brand-secondary {
          color: #27e1ba; 
      }
      .budicon-phone{
          width:25px;
          margin:0; 
          height:25px;
          margin-left: -9px;
      }
      .container {
        margin: 0 auto;
        min-width: 150px;
        width: 150px;
        height: 150px;
        background-image: url("`+ user.profileImageBase64 + `");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-radius: 50%;
      }
      </style>
      </head>
      <body>
          <div class="id-card-wrapper">
              <div class="id-card">
                <div class="header-row">
                    <div class="dp">
                      <img class="logo" src="../../../assets/images/RMFGroup.jpg" alt="profile image">
                    </div>
                    <div class="desc">
                      <span class="header-text">RMF Construction Training Academy LTD</span>
                    </div>
                </div>
                <div class="profile-row">
                  <div class="dp">
                    <div class="container"></div>
                    <div class="desc">
                      <span style="font-size: 1.5rem;">`+ user.name + ` ` + user.surname + `</span>
                    </div>
                  </div>
                  <div class="qrcode">
                    <img src="`+ user.qrcodeBase64 + `">
                  </div>
                </div>
              </div>
            </div>
      </body>
      </html>`;
    return html;
  }
}

