import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from "@angular/router";
import { ErrorHandler } from '../../services/errorhandling';
import { AllowedServicesConstants as Constants } from '../constants/allowedServices.constant'

@Injectable({
    providedIn: 'root'
})
export class AllowedServicesGuard implements CanActivate {

    private allowedServices: string[];

    constructor(private errorHandler: ErrorHandler, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let userData = JSON.parse(localStorage.getItem('userData'));
        if (!userData) {
            this.router.navigate(['/']);
            return false;
        }

        this.allowedServices = userData.allowedServices;
        let allowedServices = route.data["roles"];
        let allowed: boolean = false;
        this.allowedServices.forEach(
            (allowedService) => {
                allowedServices.forEach(element => {
                    if (allowedService === element) {
                        allowed = true;
                    }
                });
            });
        if (!allowed) {
            this.errorHandler.showLocalizedMessage('You do not have permission to access.', 'error')
            if (this.allowedServices.length == 1 && this.allowedServices[0] === Constants.LOGIN) {
                localStorage.removeItem('userData')
                this.router.navigate(['/']);
            }
        }
        return allowed;
    }
}