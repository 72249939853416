import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AllowedServicesConstants as Constants } from '../shared/constants/allowedServices.constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() pageTitle: string;
  public readonly CONSTANTS = Constants;
  public  userRole:string;

  constructor(private router: Router) { }

  ngOnInit() { 
    this.userRole = JSON.parse(localStorage.getItem('userData')).userRole || '';

  }

  doLogout() {
    localStorage.removeItem('userData');
    this.router.navigate(['/']);
  }

  loadPage() {
    if (this.router && this.router.url === '/report/logs') { //or your current page url e.g '/home' 
      window.location.reload();
    } else {
      this.router.navigate(['/report/logs']);
    }
  }
}
