import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class RestrictLoginWhileSessionExistGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let userData = JSON.parse(localStorage.getItem('userData'));
        if (userData) {
            this.router.navigate(['/report/logs']);
        }
        return true;
    }
}