import { Component, OnInit, Input } from '@angular/core';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-fullscreenLoader',
  templateUrl: './fullscreenLoader.component.html',
  styleUrls: ['./fullscreenLoader.component.scss']
})
export class FullscreenLoaderComponent implements OnInit {

  constructor(public loaderService:LoaderService) { }

  ngOnInit() {
  }

}