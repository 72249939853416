import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { ReportService } from '../../../services/report.service';

import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { UserService } from '../../../services/user.service';
const { range } = extendMoment(moment);

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LogHistoryComponent } from '../log-history/log-history.component';

@Component({
  selector: 'app-logs-report',
  templateUrl: './logs-report.component.html',
  styleUrls: ['./logs-report.component.css']
})
export class LogsReportComponent implements OnInit {

  public reports: any = {};
  public isLoading: boolean = false;
  public searchQuery: string = "";
  public pageNo: number = 1;
  public pageSize: number = 10;

  public fromDate: Date = new Date();
  public toDate: Date = null;
  public month = "0";
  public week = "0";
  public weeks = [];

  public location = "0";
  public disableLocationDD: boolean = false;
  public locationList = [];

  public userType = "0";
  public disableUserType: boolean = false;

  // for advanced filter
  public advancedFilterFirstDate: Date = null;
  public advancedFilterLastDate: Date = null;
  public advancedFilterWeekFirstDate: Date = null;
  public advancedFilterWeekLastDate: Date = null;

  public pageLoad: boolean = false;

  // modal variables
  bsModalRef: BsModalRef;

  constructor(private reportService: ReportService,
    private userService: UserService,
    private modalService: BsModalService) {
    this.fromDate.setDate(this.fromDate.getDate() - 1);
    this.pageLoad = true;
  }

  ngOnInit() {
    let self = this;
    let userRole = JSON.parse(localStorage.getItem('userData')).userRole || '';
    if(userRole &&  userRole === 'ROLE_TRAINEE_ADMIN')
    {
      this.userType="1";
      this.disableUserType=true;
    }
    self.getLocations();
    setTimeout(() => {
      self.fetchlogReport();
      this.pageLoad = false;
    }, 1000);
  }

  public fetchReport() {
    this.pageNo = 1;
    this.fetchlogReport();
  }

  public resetPageSize(pageSize) {
    this.pageNo = 1;
    this.pageSize = pageSize;
    this.fetchlogReport();
  }

  public pageChanged(event: any): void {
    this.pageNo = event.page;
    this.pageSize = event.itemsPerPage;
    this.fetchlogReport();
    window.scrollTo(0, 0);
  };

  clearName() {
    if (this.searchQuery != "") {
      this.searchQuery = "";
      this.pageNo = 1;
      this.fetchlogReport();
    }
  }

  clearDateRange() {
    if (null != this.fromDate) {
      this.fromDate = null;
      this.toDate = null;
      this.pageNo = 1;
      this.fetchlogReport();
    }
  }

  fromDateChange(event) {
    if (event && !this.pageLoad) {
      this.fromDate = event;
      this.pageNo = 1;
      this.fetchlogReport();
    }
  }

  toDateChange(event) {
    if (event) {
      this.toDate = event;
      this.pageNo = 1;
      this.fetchlogReport();
    }
  }

  monthChange() {
    this.week = "0";
    this.weeksDD();
    this.fetchlogReport();
  }

  userTypeChange() {
    if (!this.pageLoad) {
      this.fetchlogReport();
    }
  }


  weekChange() {
    let today = new Date();
    let month = parseInt(this.month) - 1;
    let year = today.getFullYear();
    let date = moment.utc([today.getFullYear(), month]);

    let firstDay = moment(date).startOf('month');
    let endDay = moment(date).endOf('month');

    var weeknumber = parseInt(this.week);
    let index = this.weeks.indexOf(weeknumber);

    let firstWeekDay = moment().year(year).month(month).week(weeknumber).day(0);
    let lastWeekDay = moment().year(year).month(month).week(weeknumber).day(6);
    if (month == 11 && (this.weeks.length - 1) == index) {
      firstWeekDay = moment().year(year).month(month).week(this.weeks[index - 1]).day(0);
      firstWeekDay.add(7, "day");
      lastWeekDay = moment().year(year).month(month).week(this.weeks[index - 1]).day(6);
      lastWeekDay.add(6, "day");
    }

    if (firstWeekDay.isBefore(firstDay)) {
      firstWeekDay = firstDay;
    }

    if (lastWeekDay.isAfter(endDay)) {
      lastWeekDay = endDay;
    }

    this.advancedFilterWeekFirstDate = firstWeekDay.toDate();
    this.advancedFilterWeekLastDate = lastWeekDay.toDate();
    this.fetchlogReport();
  }

  fetchlogReport() {
    let filterParams = { filters: { user: '' } };
    this.buildSearchAndFiltersCriteria(filterParams);
    this.isLoading = true;
    this.reportService.fetchlogReport(this.pageNo, this.pageSize, filterParams).subscribe(
      res => {
        if (res.status === 200) {
          this.reports = res.data[0];
        }
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        //this.errorHandler.handleError(err);
      }
    );
  }

  private buildSearchAndFiltersCriteria(filterParams: any) {
    if (this.searchQuery)
      filterParams['filters']['user'] = this.searchQuery;

    if (this.location && this.location !== "0")
      filterParams['filters']['location'] = this.location;

    if (this.userType && this.userType !== "0")
      filterParams['filters']['userType'] = this.userType;

    if (this.fromDate)
      filterParams['filters']['fromDate'] = formatDate(this.fromDate, 'yyyy-MM-dd', 'en_US');

    if (this.toDate)
      filterParams['filters']['toDate'] = formatDate(this.toDate, 'yyyy-MM-dd', 'en_US');

    if (this.month && this.month !== "0") {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), parseInt(this.month) - 1, 1);
      var lastDay = new Date(today.getFullYear(), parseInt(this.month), 0);
      filterParams['filters']['fromDate'] = formatDate(firstDay, 'yyyy-MM-dd', 'en_US');
      filterParams['filters']['toDate'] = formatDate(lastDay, 'yyyy-MM-dd', 'en_US');
      this.advancedFilterFirstDate = firstDay;
      this.advancedFilterLastDate = lastDay;
    }
    if (this.week && this.week !== "0") {
      filterParams['filters']['fromDate'] = formatDate(this.advancedFilterWeekFirstDate, 'yyyy-MM-dd', 'en_US');
      filterParams['filters']['toDate'] = formatDate(this.advancedFilterWeekLastDate, 'yyyy-MM-dd', 'en_US');
      this.advancedFilterFirstDate = this.advancedFilterWeekFirstDate;
      this.advancedFilterLastDate = this.advancedFilterWeekLastDate;
    }
  }

  weeksDD() {
    this.weeks = [];
    if (this.month && this.month !== "0") {
      let today = new Date();
      let month = parseInt(this.month) - 1;
      let date = moment.utc([today.getFullYear(), month]);

      let firstDay = moment(date).startOf('month');
      let endDay = moment(date).endOf('month');

      let monthRange = range(firstDay, endDay);

      for (let mday of monthRange.by('days')) {
        if (this.weeks.indexOf(mday.week()) === -1) {
          this.weeks.push(mday.week());
        }
      }
    }
  }

  exportExcel(event?: any) {
    event.stopPropagation();
    event.preventDefault();
    let filterParams = { filters: { user: '' } };
    this.buildSearchAndFiltersCriteria(filterParams);
    this.reportService.exportlogReport(filterParams).subscribe(
      res => {
        var file = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'User Log History.xlsx';
        document.body.appendChild(a);
        a.click();
      },
      err => {

      }
    );
  }

  getLocations() {
    let userLocation = JSON.parse(localStorage.getItem('userData')).location || '';
    this.reportService.getLocations().subscribe(
      (res) => {
        if (res.status === 200) {
          this.locationList = res.data;
          this.locationList.forEach((loacation: any) => {
            if (loacation.name === userLocation) {
              this.disableLocationDD = true;
              this.location = loacation.name;
            }
          });
        } else {
          console.error('No locations');
        }
      }, (err) => {

      }
    );
  }

  openLogHistoryModal(event: any, name: string, surname: string, userHistoryList: any) {
    event.stopPropagation();
    event.preventDefault();
    userHistoryList.forEach((element: any) => {
      if (element.checkOutTime) {
        element.step = 'step';
      } else {
        element.step = '';
      }
    });
    // sort date 
    userHistoryList.sort((a, b) => a.checkInTime - b.checkInTime);
    const initialState = {
      userHistoryList: userHistoryList,
      userName: name + ' ' + surname
    };
    this.bsModalRef = this.modalService.show(LogHistoryComponent, { backdrop: 'static', class: 'modal-dialog-centered', initialState });
    this.bsModalRef.content.action
      .subscribe((value) => {
        this.bsModalRef.hide();
      }, (err) => {
        console.log("error occured while closing popup");
        this.bsModalRef.hide();
      });
  }
}
